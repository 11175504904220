import styled from "styled-components";

export const Footer = styled.footer`
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
  background: ${({ theme }) => theme.colors.backgroundFooter};
  padding: 5rem 0 2.5rem;
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.colors.borderTopFooter};
  transition: background-color 0.45s ease-out;

  @media screen and (min-width: 576px) {
    text-align: initial;
  }

  > a {
    position: absolute;
    top: -24px;
    margin: auto;
    left: 0;
    right: 0;
  }
`;

export const FooterContent = styled.div`
  width: 100%;
  padding: 0 1.25rem;

  @media screen and (min-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 2rem;
    margin-bottom: 2.5rem;
  }

  @media screen and (min-width: 1024px) {
    padding: 0 1rem;
    max-width: 1140px;
    row-gap: unset;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const About = styled.div`
  @media screen and (min-width: 576px) {
    flex-basis: 100%;
    padding: 0 1.5rem;
    text-align: center;
  }

  @media screen and (min-width: 1024px) {
    flex-basis: 35%;
    padding: 0;
  }

  @media screen and (min-width: 1200px) {
    flex-basis: 40%;
  }

  .description {
    .gatsby-image-wrapper {
      margin: 0 auto 1.5rem;

      @media screen and (min-width: 576px) {
        margin: 0 auto 1.25rem;
      }
    }

    p {
      font-size: ${({ theme }) => theme.fontSize.sm};
      font-weight: 300;
      line-height: 20px;
      margin-bottom: 1.25rem;

      @media screen and (min-width: 576px) {
        font-size: ${({ theme }) => theme.fontSize.md};
        text-align: center;
        padding: 0 2rem;
      }

      @media screen and (min-width: 768px) {
        text-align: unset;
      }

      @media screen and (min-width: 1024px) {
        padding: 0;
      }
    }
  }

  .social-media {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1.25rem;
    margin-bottom: 2.5rem;

    @media screen and (min-width: 576px) {
      justify-content: center;
      margin-bottom: 0;
    }

    a {
      svg {
        width: 40px;
        height: 40px;

        path {
          fill: ${({ theme }) => theme.colors.iconFooter};
          transition: all 0.25s ease;
        }

        :hover {
          path {
            opacity: 0.7;
          }
        }
      }
    }
  }
`;

export const Areas = styled.div`
  margin-bottom: 2.5rem;

  @media screen and (min-width: 576px) {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 0;
    padding: 0 1.5rem;
  }

  @media screen and (min-width: 768px) {
    flex-basis: 45%;
    text-align: unset;
  }

  @media screen and (min-width: 1024px) {
    flex-basis: 25%;
    padding: 0 1rem;
  }

  @media screen and (min-width: 1200px) {
    flex-basis: 30%;
  }

  ul {
    margin-bottom: 0;

    li {
      margin-bottom: 1rem;

      :last-child {
        margin-bottom: 0rem;
      }

      a {
        font-size: ${({ theme }) => theme.fontSize.sm};
        font-weight: 300;
        color: ${({ theme }) => theme.colors.text};
        transition: all 0.25s ease;

        @media screen and (min-width: 576px) {
          font-size: ${({ theme }) => theme.fontSize.md};
        }

        :hover {
          color: ${({ theme }) => theme.colors.secondaryLight};
        }
      }
    }
  }
`;

export const Info = styled.div`
  @media screen and (min-width: 576px) {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 0;
    padding: 0 1.5rem;
  }

  @media screen and (min-width: 768px) {
    flex-basis: 45%;
    text-align: unset;
    padding: 0;
  }

  @media screen and (min-width: 1024px) {
    flex-basis: 25%;
    margin-bottom: 3rem;
  }

  @media screen and (min-width: 1200px) {
    flex-basis: 30%;
  }
`;

export const Contact = styled.div`
  margin-bottom: 2.5rem;

  svg {
    display: none;
  }

  @media screen and (min-width: 350px) {
    svg {
      display: block;
    }
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 3rem;
  }

  ul {
    margin-bottom: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.5rem;
      margin-bottom: 1rem;

      @media screen and (min-width: 768px) {
        justify-content: flex-start;
      }

      :last-child {
        margin-bottom: 0rem;
      }

      svg {
        width: 16px;
        height: 16px;
        fill: ${({ theme }) => theme.colors.iconFooter};
        transition: fill 0.45s ease;
      }

      span {
        font-size: ${({ theme }) => theme.fontSize.sm};
        font-weight: 300;
        color: ${({ theme }) => theme.colors.text};
        transition: all 200ms linear;

        @media screen and (min-width: 576px) {
          font-size: ${({ theme }) => theme.fontSize.md};
        }
      }
    }
  }
`;

export const WorkingHours = styled.div`
  margin-bottom: 2.5rem;

  @media screen and (min-width: 576px) {
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 300px;
      margin: 0 auto;
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;

      @media screen and (min-width: 576px) {
        max-width: 350px;
        margin: 0 auto;
      }

      @media screen and (min-width: 768px) {
        margin: 0 auto 0 0;
      }

      :last-child {
        border-bottom: none;
        padding-bottom: 0rem;
        margin-bottom: 0rem;
      }

      span {
        font-size: ${({ theme }) => theme.fontSize.sm};
        font-weight: 300;
        color: ${({ theme }) => theme.colors.text};
        transition: all 200ms linear;

        @media screen and (min-width: 576px) {
          font-size: ${({ theme }) => theme.fontSize.md};
        }
      }
    }
  }
`;

export const FooterBottom = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray};
  padding-top: 2rem;

  > p {
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: 300;
    text-align: center;
    line-height: 20px;
    margin-bottom: 1.5rem;
  }
`;

export const RedirectList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  margin-bottom: 0;
`;

export const RedirectItem = styled.li`
  position: relative;

  a {
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSize.md};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
    transition: all 0.45s ease;

    :after {
      position: absolute;
      content: "";
      top: 19px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.secondaryLight};
      opacity: 0.7;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.5s;
    }

    :hover {
      opacity: 0.7;

      :after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }

  i {
    margin: 0 0.5rem;
  }
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  transition: all 200ms linear;
`;
