import styled from "styled-components";

export const BacktoTopButton = styled.a`
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.grayLight};
  cursor: pointer;
  transition: all 0.25s linear;

  /* opacity: 0;
  visibility: hidden;
  transform: translateY(15px); */

  :hover {
    .icon,
    .stroke {
      path {
        transition: all 0.25s linear;
        opacity: 0.8;
      }
    }
  }

  /* &.visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  } */

  .icon {
    position: absolute;
    fill: ${({ theme }) => theme.colors.secondary};
    transition: fill 0.45s ease;
    animation: arrow-right 1s infinite alternate;

    @keyframes arrow-right {
      0% {
        transform: translateY(-0.2em);
      }
      100% {
        transform: translateY(0.1em);
      }
    }
  }

  .stroke {
    path {
      fill: none;
      box-sizing: border-box;
      transition: stroke-dashoffset 10ms linear 0s;
      stroke: ${({ theme }) => theme.colors.secondary};
      stroke-width: 7px;
      stroke-dasharray: 307.919, 307.919;
    }
  }
`;
