import styled, { css } from "styled-components";

const visibleCss = css`
  z-index: ${props => (props.visible ? 21 : -1)};
`;

export const MoonIcon = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  ${visibleCss};
`;

export const SunIcon = styled.div`
  position: absolute;
  top: 0px;
  right: 3px;
  ${visibleCss};
`;

export const StyledSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin: 0;
  cursor: pointer;

  input[type="checkbox"] {
    display: none;

    :checked {
      + span:before {
        transform: translateX(25px);
        background-color: ${({ theme }) => theme.colors.white};
        z-index: 1;
      }

      + span {
        background-color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  span {
    position: absolute;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;

    :before {
      position: absolute;
      z-index: 3;
      content: "";
      left: 3px;
      top: 2px;
      width: 20px;
      height: 20px;
      background-color: ${({ theme }) => theme.colors.background};
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
  }
`;
