import "./static/fonts/fonts.css";
import "./static/styles/preloader.css";
import "/node_modules/slick-carousel/slick/slick-theme.css";
import "/node_modules/slick-carousel/slick/slick.css";
import ReactDOM from "react-dom/client";
export { wrapRootElement } from "./gatsby/wrapRootElement";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
