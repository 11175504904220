import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { ThemeContext } from "styled-components";
import * as S from "./styles";

const Logo = () => {
  const { title } = useContext(ThemeContext);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <S.Logo>
      <Link to="/">
        {title === "dark" ? (
          isDesktop ? (
            <StaticImage
              src="../../images/logo-white.png"
              width={160}
              layout="fixed"
              loading="eager"
              alt="logo do site escrito GM Advogados"
            />
          ) : (
            <StaticImage
              src="../../images/logo-white.svg"
              width={60}
              layout="fixed"
              loading="eager"
              alt="logo do site escrito GM Advogados"
            />
          )
        ) : isDesktop ? (
          <StaticImage
            src="../../images/logo-black.png"
            width={160}
            layout="fixed"
            loading="eager"
            alt="logo do site escrito GM Advogados"
          />
        ) : (
          <StaticImage
            src="../../images/logo-black.svg"
            width={60}
            layout="fixed"
            loading="eager"
            alt="logo do site escrito GM Advogados"
          />
        )}
      </Link>
    </S.Logo>
  );
};

export default Logo;
