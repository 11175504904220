import { font, fontSize } from "../theme";

const dark = {
  title: "dark",

  colors: {
    secondary: "#2d60b8",
    secondaryLight: "#627aa3",
    secondaryDark: "#1e3a6b",
    success: "#53d769",
    successDark: "#32aa48",
    successLight: "#77cc86",

    background: "#333333",
    backgroundFooter: "#333333",
    borderTopFooter: "#ffffff",
    headerShadow: "rgba(138, 155, 165, 0.15)",
    cardShadow: "rgba(255, 255, 255, 0.055)",
    cardCarouselShadow: "rgba(255, 255, 255, 0.1)",
    backgroundImageCarousel: "rgba(54, 54, 54, 0.7)",
    dropdownShadow: "rgba(255, 255, 255, 0.2)",
    text: "#ffffff",
    textGray: "#bdbdbd",
    textLight: "#212121",
    black: "#212121",
    white: "#ffffff",

    iconFooter: "#ffffff",
    backToTopBg: "#b8b8b8",
    linkActive: "#9f9f9f",
    lineLinkHover: "#8167a9",
    burgerIconBg: "#ffffffb4",
    tableRowHover: "#5c5c5c",
    gray: "#9a9a9a",
    grayLight: "#d8d8d8",
    grayLightest: "#ebebeb",
  },

  font,
  fontSize,
};

export default dark;
