import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Icon from "../Icons/icon";
import * as S from "./styles";

const Dropdown = ({ title, dropdownItems = null, subDropdownItems = null }) => (
  <S.DropdownContainer>
    <S.DropdownInput type="checkbox" id="dropdown" name="dropdown" />
    <label htmlFor="dropdown">
      {title} <Icon name="ChevronDown" />
    </label>

    <S.DropdownSection>
      {dropdownItems.map(({ anchor, label }, index) => (
        <Link key={index} to={anchor}>
          {label}
        </Link>
      ))}

      {subDropdownItems &&
        subDropdownItems.map(({ title, name, items }, index) => (
          <div key={index}>
            <S.SubDropdownInput type="checkbox" id={name} name={name} />
            <label htmlFor={name}>
              {title} <Icon name="ChevronDown" />
            </label>

            <S.SubDropdownSection>
              {items.map(({ anchor, label }, index) => (
                <S.SubLink key={index}>
                  <Link to={anchor}>{label}</Link>
                </S.SubLink>
              ))}
            </S.SubDropdownSection>
          </div>
        ))}
    </S.DropdownSection>
  </S.DropdownContainer>
);

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  dropdownItems: PropTypes.arrayOf(PropTypes.object),
  subDropdownItems: PropTypes.arrayOf(PropTypes.object),
};

export default Dropdown;
