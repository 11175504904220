import React from "react";
import Icon from "../Icons/icon";
import * as S from "./styles";

const HeaderTop = () => (
  <S.HeaderTopContainer>
    <S.HeaderTop>
      <ul>
        <li>
          <a href="mailto:guimaraesmedeirosadv@gmail.com?subject=Assunto do email&body=Conteúdo do email">
            <Icon name="Mail" aria-label="Ícone do email" />
            <span>guimaraesmedeirosadv@gmail.com</span>
          </a>
        </li>
        <li>
          <a href="tel:(61) 98300-2735">
            <Icon name="Phone" aria-label="Ícone do telefone" />
            <span>(61) 9 8300-2735</span>
          </a>
        </li>
      </ul>

      <div>
        <a
          href="https://www.facebook.com/JeanMedeirosAdv"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="Facebook" aria-label="Ícone do facebook" />
        </a>
        <a
          href="https://www.instagram.com/jeaneverson/"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="Linkedin" aria-label="Ícone do linkedin" />
        </a>
        <a
          href="https://www.instagram.com/jeaneverson/"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="Instagram" aria-label="Ícone do instagram" />
        </a>
        <a href="https://wa.me/5561983002735" target="_blank" rel="noreferrer">
          <Icon name="Whatsapp" aria-label="Ícone do whatsapp" />
        </a>
      </div>
    </S.HeaderTop>
  </S.HeaderTopContainer>
);

export default HeaderTop;
