import PropTypes from "prop-types";
import React from "react";

import * as S from "./styles";

const MobileMenu = ({ checked, toggleMenu }) => {
  return (
    <S.MobileMenu>
      <S.MobileMenuInput
        id="menu-input"
        type="checkbox"
        defaultChecked={checked}
        onClick={toggleMenu}
      />

      <S.MobileMenuIcon htmlFor="menu-input">
        <S.Line />
        <S.Line />
        <S.Line />
      </S.MobileMenuIcon>
    </S.MobileMenu>
  );
};

MobileMenu.propTypes = {
  checked: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default MobileMenu;
