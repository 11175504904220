import styled, { css } from "styled-components";

const dropdownLink = css`
  position: relative;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  transition: all 200ms linear;

  @media screen and (min-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;

const linkHover = css`
  :hover {
    opacity: 0.7;

    svg {
      opacity: 0.7;
    }
  }
`;

export const DropdownSection = styled.div`
  position: absolute;
  top: 60px;
  left: -180px;
  display: block;
  width: 260px;
  padding: 1rem 1rem;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 4px;
  box-shadow: 0 3px 10px 0 ${({ theme }) => theme.colors.dropdownShadow};
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;

  @media screen and (min-width: 768px) {
    left: -110px;
  }

  :before {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 20px;
    content: "";
    display: block;
    z-index: 1;
  }

  :after {
    position: absolute;
    top: -7px;
    left: 200px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid ${({ theme }) => theme.colors.background};
    content: "";
    display: block;
    z-index: 2;
    transition: all 200ms linear;

    @media screen and (min-width: 768px) {
      left: 140px;
    }
  }

  > a {
    display: inline-block;
    margin: 0.5rem 0;
    ${dropdownLink};

    :after {
      position: absolute;
      content: "";
      top: 19px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.secondaryLight};
      opacity: 0.7;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.5s;
    }

    :hover {
      opacity: 0.7;

      :after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
`;

export const SubDropdownSection = styled.div`
  position: relative;
  display: block;
  width: 100%;
  max-height: 0;
  pointer-events: none;
  opacity: 0;
  padding-left: 0.50rem;
  overflow: hidden;
  transition: all 200ms linear;

  a {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const DropdownInput = styled.input`
  :checked,
  :not(:checked) {
    + label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 21px;
      ${dropdownLink};
      ${linkHover};

      @media screen and (min-width: 380px) {
        line-height: unset;
      }

      :before {
        position: fixed;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        z-index: -1;
        cursor: auto;
        pointer-events: none;
      }
    }

    :checked {
      + label {
        :before {
          pointer-events: auto;
        }
        svg {
          margin-left: 0.5rem;
          transform: rotate(180deg);
          transition: transform 200ms linear;
        }
        ~ ${DropdownSection} {
          opacity: 1;
          pointer-events: auto;
          transform: translateY(0);
        }
      }
    }

    :not(:checked) + label svg {
      margin-left: 0.5rem;
      transition: transform 200ms linear;
    }
  }
`;

export const SubDropdownInput = styled.input`
  :checked,
  :not(:checked) {
    + label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.35rem 0;
      ${dropdownLink};
      ${linkHover};
    }
  }

  :not(:checked) + label svg {
    transition: transform 200ms linear;
  }

  :checked {
    + label svg {
      transform: rotate(180deg);
      transition: transform 200ms linear;
    }
    ~ ${SubDropdownSection} {
      pointer-events: auto;
      opacity: 1;
      max-height: 999px;
    }
  }
`;

export const SubLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a {
    flex-basis: 100%;
    margin: 0.5rem 0;
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
    transition: all 200ms linear;

    @media screen and (min-width: 768px) {
      font-size: ${({ theme }) => theme.fontSize.sm};
    }

    :hover {
      opacity: 0.7;

      + svg {
        opacity: 0.7;
      }
    }
  }

  svg {
    flex-basis: 18px;
    width: 18px;
    height: 18px;
  }
`;

export const DropdownContainer = styled.div`
  z-index: 2;
  position: relative;
  max-width: 100%;

  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
    opacity: 0;
    pointer-events: none;
  }
`;
