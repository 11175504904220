import styled from "styled-components";

export const Logo = styled.div`
  a {
    display: flex;
    align-items: center;
    column-gap: 10px;
    text-decoration: none;
    outline: none;
  }
`;
