import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container } from "../../styles/general-styles";
import HeaderTop from "../HeaderTop";
import Navbar from "../NavBar";
import Switch from "../Switch";
import * as S from "./styles";

const Header = ({ toggleTheme }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const isBrowser = typeof window !== "undefined";

  useEffect(() => {
    if (isBrowser) {
      window.onscroll = () => setScrollPosition(window.scrollY);
    }
  }, [isBrowser]);

  return (
    <React.Fragment>
      <HeaderTop />

      <S.Header retract={scrollPosition >= 30}>
        <Container>
          <Navbar switchButton={<Switch toggleTheme={toggleTheme} />} />
        </Container>
      </S.Header>
    </React.Fragment>
  );
};

Header.propTypes = {
  toggleTheme: PropTypes.func.isRequired,
};

export default Header;
