import React from 'react';

const IconRealEstateLaw = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" version="1.0" viewBox="0 0 512 512">
    <title>Direito Imobiliário</title>
    <path fill="currentColor"
        d="M220 60.8c-17.9 14.1-71.3 56.1-118.5 93.4-47.3 37.2-87 68.7-88.1 70-4.2 4.5-5.8 9-5.8 15.8 0 13.8 9.7 23.4 23.4 23.4 7.9 0 10.9-1.3 21.5-9.8l8-6.4.3 112.6.2 112.6 2.2 2.3 2.1 2.3h381.4l2.1-2.3 2.2-2.3.2-112.6.3-112.6 8 6.4c10.8 8.6 13.6 9.9 21.5 9.9 9.8-.1 16.9-4.6 21.6-13.5 2.7-5.1 2.5-15.2-.3-20.6-2.9-5.4-4.1-6.5-51.2-43.4L411 154.5v-48.3c0-46.7-.1-48.3-2-50.2-1.1-1.1-3-2.2-4.2-2.5-1.3-.3-15-.5-30.5-.3-27.9.3-28.3.3-30.5 2.6-2.2 2.2-2.3 3.1-2.8 22.7l-.5 20.4L300 67c-35.7-28.1-40.9-31.9-44-31.9-3.1 0-7.6 3.2-36 25.7zm100.7 42.4c35.1 27.6 67.9 53.3 72.8 57.3 5 3.9 27 21.2 49 38.5 22 17.2 41.5 32.7 43.3 34.4 6 5.6 3.2 14.6-4.7 14.6-3 0-5.4-1.5-14.6-8.7-6-4.8-40.5-31.9-76.5-60.2-36-28.3-80.3-63.1-98.3-77.3C266.4 81.9 258.3 76 256.1 76c-2.3 0-25.8 18-112.2 86-100.3 78.9-109.6 86-113 86-4.6 0-7.9-3.6-7.9-8.6 0-2.7.9-4 4.8-7.3C37.9 223.5 255.2 53 256 53c.5 0 29.6 22.6 64.7 50.2zM396 105c0 23.9-.3 36-1 36-.6 0-9.4-6.6-19.5-14.6L357 111.9V69h39v36zm-49.1 60 89.1 70.1V462H76V235.2l89.7-70.6c49.4-38.9 90.3-70.4 90.9-70.2.6.2 41.2 32 90.3 70.6z" />
    <path fill="currentColor"
        d="M243.7 176.9c-13.3 13.2-14.2 15.2-9.7 21.3l2.2 3.1-27 27-27.1 27.1-2.6-2.4c-1.7-1.6-3.7-2.4-6.1-2.4-3.2 0-4.7 1.1-15.5 12-10.6 10.7-11.9 12.4-11.9 15.5 0 3.2 2.3 5.8 30.8 34.2 28.1 28 31.1 30.7 34.1 30.7s4.8-1.5 15.2-11.8c10.9-10.7 11.9-12 11.9-15.4 0-2.4-.8-4.6-2.4-6.6l-2.5-2.9 6.5-6.4 6.4-6.4 20.8 20.8c18.9 18.9 20.6 20.9 19.4 22.7-3.3 5.3-3.3 5.3 37.6 46.2 31.3 31.4 39.3 38.9 42 39.4 3.3.7 3.8.2 20.8-16.7 14.6-14.7 17.4-17.9 17.4-20.4s-5.4-8.3-39.2-42.2c-41.5-41.5-41.4-41.4-46.8-38.1-1.8 1.2-3.8-.5-22.7-19.4L274.5 265l6.4-6.4 6.4-6.5 2.9 2.5c1.9 1.6 4.2 2.4 6.5 2.4 3.3 0 4.7-1.1 15.5-11.8 10.5-10.5 11.8-12.3 11.8-15.3 0-3.1-2.4-5.8-30.8-34.2-28.3-28.3-31-30.7-34.1-30.7-3 0-4.8 1.4-15.4 11.9zm38.8 30.1 22.9 23-3.9 4c-2.1 2.2-4.2 4-4.5 4-.3 0-11-10.3-23.6-23l-23-22.9 4-4.1c2.3-2.2 4.3-4 4.6-4 .3 0 10.9 10.3 23.5 23zm-33.2 61.2L222 295.5 207.5 281 193 266.5l27.2-27.2 27.3-27.3 14.5 14.5 14.5 14.5-27.2 27.2zM219 315.5c0 .6-1.8 2.9-4 5l-4 3.9-23.3-23.2-23.2-23.2 4.5-4.5 4.4-4.5 22.8 22.7c12.6 12.5 22.8 23.3 22.8 23.8zm65.8-18.2 20.2 20.3-3.3 3.2-3.3 3.2-20.4-20.5-20.4-20.4 2.9-3.1c1.6-1.6 3.1-3 3.4-3 .4 0 9.7 9.1 20.9 20.3zm91 97.4-9.3 9.3-31.2-31.2-31.3-31.3 9.2-9.2 9.3-9.3 31.2 31.2 31.3 31.3-9.2 9.2zM136.5 377.4c-15.9 5.1-26.7 18.1-28.1 33.7-.6 5.5-.3 6.8 1.4 8.9l2 2.5 67.7.3 67.6.2 3-2.9c3.2-3.2 3.4-4.3 1.9-12.2-2.3-12-11.3-23.3-22.7-28.7l-5.8-2.7-41.5-.2c-31.7-.2-42.4.1-45.5 1.1zm86.5 16c4.4 2.3 9.8 7.3 11.6 10.8l1.5 2.8H124.8l2.5-4.3c2.8-4.7 9.8-9.6 15.5-10.8 2-.4 20.1-.7 40.2-.6 33.5.2 36.8.4 40 2.1z" />
  </svg>
);

export default IconRealEstateLaw;
