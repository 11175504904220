import React, { useEffect, useState } from "react";
import * as S from "./styles";

// Retorna a altura máxima de toda a página
function getTotalPageHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );
}

// (Intuito de reutilizar função) Calcula a posição do scroll
export function getScrollPosition() {
  const scrollYPosition = window.scrollY;
  const visualScreenHeight = window.innerHeight;
  const totalPageHeight = getTotalPageHeight();

  const remainderOfTotalPageHeight = totalPageHeight - visualScreenHeight;
  const screenPosition = scrollYPosition / remainderOfTotalPageHeight;

  return screenPosition;
}

const BackToTopButton = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  // Volta para o topo da tela
  function backToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  // Ouve o evento de scroll
  function listenToScrollEvent() {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        calculateScrollPosition();
      });
    });
  }

  // Define o valor da posição do scroll
  // e seta o desenho do SVG
  function calculateScrollPosition() {
    const currentScreenPosition = getScrollPosition();
    setScrollPosition(currentScreenPosition);
  }

  useEffect(() => {
    listenToScrollEvent();
  });

  return (
    <S.BacktoTopButton onClick={backToTop}>
      <svg className="icon" width="45" height="45" viewBox="-10 0 45 25">
        <path d="M6.7,18.29L6.7,18.29c0.39,0.39,1.02,0.39,1.41,0L12,14.42l3.88,3.88c0.39,0.39,1.02,0.39,1.41,0l0,0 c0.39-0.39,0.39-1.02,0-1.41l-4.59-4.59c-0.39-0.39-1.02-0.39-1.41,0L6.7,16.88C6.31,17.27,6.31,17.9,6.7,18.29z" />
        <path d="M6.7,11.7L6.7,11.7c0.39,0.39,1.02,0.39,1.41,0L12,7.83l3.88,3.88c0.39,0.39,1.02,0.39,1.41,0l0,0 c0.39-0.39,0.39-1.02,0-1.41l-4.59-4.59c-0.39-0.39-1.02-0.39-1.41,0L6.7,10.29C6.31,10.68,6.31,11.31,6.7,11.7z" />
      </svg>

      <svg className="stroke" width="45" height="45" viewBox="-3 -3 106 106">
        <path
          d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          style={{ strokeDashoffset: 307.919 + 307.919 * -scrollPosition }}
        ></path>
      </svg>
    </S.BacktoTopButton>
  );
};

export default BackToTopButton;
