import * as React from "react";
import Icon from '../components/Icons/icon';

// Areas Component
export const areasCardData = [
  {
    icon: <Icon name="PensionLaw" />,
    title: "Aposentadoria e Auxílios ㅤㅤㅤㅤㅤㅤㅤ",
    description:
      "Aposentadoria por idade, tempo de contribuição, invalidez, pontos, benefício de prestação continuada...",
    page: "/aposentadoria-e-auxilios",
  },
  {
    icon: <Icon name="LaborLaw" />,
    title: "Direito Trabalhista ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ",
    description:
      "Direito que visa proteger os trabalhadores, como salários adequados, ambientes de trabalho seguros...",
    page: "/direito-trabalhista",
  },
  {
    icon: <Icon name="ConsumerCivilLaw" />,
    title: "Direito do Consumidor ㅤㅤㅤㅤㅤㅤㅤㅤㅤ",
    description:
      "Direito que visa proteger os consumidores contra práticas comerciais injustas ou enganosas...",
    page: "/direito-do-consumidor",
  },
];

// SubAreas Component
export const subAreasCardData = [
  {
    image: "aposentadoria-e-auxilios.jpg",
    title: "Aposentadoria e Auxílios",
    description: "Aposentadoria por idade, tempo de contribuição, invalidez, pontos, benefício de prestação continuada...",
    page: "/aposentadoria-e-auxilios",
  },
  {
    image: "direito-trabalhista.jpg",
    title: "Direito Trabalhista",
    description: "Direito que visa proteger os trabalhadores, como salários adequados, ambientes de trabalho seguros...",
    page: "/direito-trabalhista",
  },
  {
    image: "direito-do-consumidor.jpg",
    title: "Direito do Consumidor",
    description: "Conheça seus direitos como consumidor e evite prejuízos com orientações legais especializadas.",
    page: "/direito-do-consumidor",
  },
  {
    image: "aposentadoria-por-idade.jpg",
    title: "Aposentadoria por Idade",
    description: "Compreenda o processo de obtenção de benefícios por idade e assegure-se de receber o que é seu por direito.",
    page: "/aposentadoria-e-auxilios/idade",
  },
  {
    image: "aposentadoria-por-idade-segurado-especial.jpg",
    title: "Aposentadoria por Idade Segurado Especial",
    description: "Descubra os requisitos e condições para acessar esse benefício e garanta que seus direitos sejam respeitados.",
    page: "/aposentadoria-e-auxilios/idade-segurado-especial",
  },
  {
    image: "aposentadoria-por-invalidez.jpg",
    title: "Aposentadoria e Auxílio por Invalidez",
    description: "Aprenda os critérios e procedimentos para obter esse benefício e assegure que seus direitos sejam atendidos.",
    page: "/aposentadoria-e-auxilios/invalidez-ou-incapacidade",
  },
  {
    image: "aposentadoria-por-tempo-contribuicao.jpg",
    title: "Aposentadoria por Tempo de Contribuição",
    description: "Descubra as etapas para acessar benefícios por tempo de contribuição e garanta que seus direitos sejam respeitados.",
    page: "/aposentadoria-e-auxilios/tempo-contribuicao",
  },
  {
    image: "auxilio-acidente.jpg",
    title: "Auxílio Acidente",
    description: "Saiba como funciona o auxílio em caso de acidente e assegure-se de receber o que é seu por direito.",
    page: "/aposentadoria-e-auxilios/acidente",
  },
  {
    image: "auxilio-doenca-acidentario.jpg",
    title: "Auxílio Doença Acidentário",
    description: "Entenda as condições para obter o auxílio em caso de doença decorrente de acidentes e garanta que seus direitos sejam protegidos.",
    page: "/aposentadoria-e-auxilios/doenca-acidentario",
  },
  {
    image: "beneficio-prestacao-continuada.jpg",
    title: "Benefício de Prestação Continuada ou Loas",
    description: "Conheça os requisitos para acessar o benefício de assistência social e assegure que seus direitos sejam devidamente atendidos.",
    page: "/aposentadoria-e-auxilios/prestacao-continuada",
  },
  {
    image: "pensao-por-morte.jpg",
    title: "Pensão por Morte",
    description: "Descubra como solicitar a pensão por morte e garanta que os direitos da família sejam respeitados e protegidos.",
    page: "/aposentadoria-e-auxilios/morte",
  },
];

// Dropdown Component
export const dropdownData = [
  { anchor: "/direito-trabalhista", label: "Direito Trabalhista" },
  { anchor: "/direito-do-consumidor", label: "Direito do Consumidor" },
];

export const subDropdownData = [
  {
    title: "Aposentadoria e Auxílios",
    name: "aposentadoria-e-auxilios",
    items: [
      {
        anchor: "/aposentadoria-e-auxilios",
        label: "Visão Geral",
      },
      {
        anchor: "/aposentadoria-e-auxilios/idade",
        label: "Aposentadoria por Idade",
      },
      {
        anchor: "/aposentadoria-e-auxilios/idade-segurado-especial",
        label: "Aposentadoria por Idade Segurado Especial",
      },
      {
        anchor: "/aposentadoria-e-auxilios/invalidez-ou-incapacidade",
        label:
          "Aposentadoria por Invalidez ou Auxílio por Incapacidade Permanente",
      },
      {
        anchor: "/aposentadoria-e-auxilios/tempo-contribuicao",
        label: "Aposentadoria por Tempo de Contribuição",
      },
      {
        anchor: "/aposentadoria-e-auxilios/acidente",
        label: "Auxílio Acidente",
      },
      {
        anchor: "/aposentadoria-e-auxilios/doenca-acidentario",
        label: "Auxílio Doença Acidentário",
      },
      {
        anchor: "/aposentadoria-e-auxilios/prestacao-continuada",
        label: "Benefício de Prestação Continuada ou Loas",
      },
      {
        anchor: "/aposentadoria-e-auxilios/morte",
        label: "Pensão por Morte",
      },
    ],
  },
];
