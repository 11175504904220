import PropTypes from "prop-types";
import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../styles/global";
import dark from "../styles/themes/dark";
import light from "../styles/themes/light";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }) => {
  const [theme, setTheme] = useState(light);

  const toggleTheme = () => {
    setTheme(theme.title === "light" ? dark : light);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header toggleTheme={toggleTheme} />
      <main>{children}</main>
      <Footer />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
