import { Link } from "gatsby";
import { BgImage } from "gbimage-bridge";
import styled, { css } from "styled-components";

export const ContainerBaseStyle = css`
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;

  @media screen and (min-width: 576px) {
    max-width: 540px;
  }
  @media screen and (min-width: 768px) {
    max-width: 720px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 1000px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const H1Base = css`
  font-family: ${({ theme }) => theme.font.title};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: 700;
  transition: all 200ms linear;

  @media screen and (min-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.xl4};
  }
`;

export const H2Base = css`
  font-family: ${({ theme }) => theme.font.main};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 200ms linear;

  @media screen and (min-width: 576px) {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`;

export const Container = styled.div`
  ${ContainerBaseStyle};
`;

export const TitleContentBase = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 1.25rem;

  @media screen and (min-width: 576px) {
    padding: 0;
  }

  h2 {
    ${H2Base};
    margin-bottom: 0.75rem;
  }

  h1 {
    letter-spacing: 1px;
    margin-bottom: 0;

    @media screen and (min-width: 576px) {
      font-size: ${({ theme }) => theme.fontSize.xl2};
    }

    ${H1Base};
  }

  hr {
    max-width: 80px;
    margin: 1.75rem auto 2.25rem;
    transition: all 200ms linear;

    @media screen and (min-width: 576px) {
      max-width: 100px;
      margin: 3rem auto;
    }
  }
`;

export const BGImageBase = styled(BgImage)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ::after {
    filter: brightness(${(props) => props.brightness ?? "0.4"});
    background-size: ${(props) => props.size ?? "cover"} !important;
    background-position: ${(props) => props.position ?? "center"} !important;
  }
`;

export const ReturnToHomeButton = styled(Link)`
  width: 200px;
  padding: 0.5rem 1rem;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text};
  background-color: transparent;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.text};
  outline: none;
  transition: all 0.25s ease;

  @media screen and (min-width: 576px) {
    width: 250px;
    font-size: ${({ theme }) => theme.fontSize.sm};
    padding: 0.75rem 2rem;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.grayLight};
  }
`;
