import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link as ScrollLink } from "react-scroll";
import { dropdownData, subDropdownData } from "../../utils/data";
import { redirectLinks } from "../../utils/redirect-links";
import Dropdown from "../Dropdown";
import Logo from "../Logo";
import MobileMenu from "../MobileMenu";
import * as S from "./styles";

const NavBar = ({ switchButton }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const [checked, setChecked] = useState(false);
  const [isPage, setIsPage] = useState(false);
  const isBrowser = typeof window !== "undefined";

  const toggleMenu = () => setChecked(!checked);

  useEffect(() => {
    if (isBrowser) {
      setIsPage(window.location.pathname.length > 1);
    }
  }, [isBrowser]);

  return (
    <S.Navbar>
      <Logo />
      <MobileMenu checked={checked} toggleMenu={toggleMenu} />

      <S.NavbarCollapse visible={checked}>
        <S.NavbarList>
          {isPage ? (
            <React.Fragment>
              <S.NavbarItem><Link to="/">Início</Link></S.NavbarItem>
              <S.NavbarItem><Link to="/">Áreas</Link></S.NavbarItem>
              <S.NavbarItem><Link to="/">Contato</Link></S.NavbarItem>
            </React.Fragment>
          ) : (
            redirectLinks.map((link, index) => (
              <S.NavbarItem key={index}>
                <ScrollLink
                  activeClass="active"
                  spy={true}
                  to={link.anchor}
                  offset={isDesktop ? -250 : -280}
                >
                  {link.text}
                </ScrollLink>
              </S.NavbarItem>
            ))
          )}

          <Dropdown
            title="Especialidades"
            dropdownItems={dropdownData}
            subDropdownItems={subDropdownData}
          />

          {isDesktop && switchButton}
        </S.NavbarList>

        {!isDesktop && switchButton}
      </S.NavbarCollapse>
    </S.Navbar>
  );
};

NavBar.propTypes = {
  switchButton: PropTypes.element.isRequired,
};

export default NavBar;
