import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`
  ${reset};

  html {
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    line-height: 1;
    overflow-y: scroll;
    scroll-behavior: smooth;
    user-select:none;
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    user-select:none;
    font-family: ${({ theme }) => theme.font.main};
    font-size: ${({ theme }) => theme.fontSize.base};
    word-wrap: break-word;
    letter-spacing: 0;
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};

    ::-webkit-scrollbar {
      width: 7px;
    }

    ::-webkit-scrollbar-track {
      background: #eeeeee;
      :hover { background: #cecece; };
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(145, 145, 145);
      :hover { background-color: rgb(119, 119, 119); };
    }

    ::-webkit-scrollbar-thumb:window-inactive {
      background-color: rgb(189, 189, 189);
    }
  }

  main {
    position: relative;
    margin: 0 auto;
  }

  a {
    background-color: transparent;
    text-decoration: none;
    font-weight: 500;
  }
  a:active,
  a:hover {
    outline-width: 0;
    text-decoration: none;
  }
  b,
  strong {
    font-weight: inherit;
    font-weight: bolder;
  }
  dfn {
    font-style: italic;
  }
  h1 {
    margin-bottom: 3rem;
    letter-spacing: -0.01em;
  }
  img {
    border-style: none;
    max-width: 100%;
  }
  code,
  kbd,
  pre,
  samp {
    font-size: 1em;
    line-height: inherit;
  }
  hr {
    box-sizing: content-box;
    overflow: visible;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding: 0;
    margin-bottom: 2rem;
    background: ${({ theme }) => theme.colors.text};
    border: none;
    height: 1px;
  }
  * {
    box-sizing: inherit;
  }
  *:before {
    box-sizing: inherit;
  }
  *:after {
    box-sizing: inherit;
  }
  hgroup,
  img,
  figure,
  fieldset,
  ul,
  ol,
  dl,
  dd,
  p {
    margin-bottom: 2rem;
  }
  ul,
  ol {
    list-style-position: outside;
    list-style-image: none;
  }
  pre {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 0.875rem;
    line-height: 1;
    background: ${({ theme }) => theme.colors.text};
    border-radius: 4px;
    overflow: auto;
    word-wrap: normal;
    padding: 2rem;
  }
  b,
  strong,
  dt,
  th {
    font-weight: bold;
  }
  ol li,
  ul li {
    padding-left: 0;
  }
  blockquote *:last-child {
    margin-bottom: 0;
  }
  li *:last-child {
    margin-bottom: 0;
  }
  p *:last-child {
    margin-bottom: 0;
  }
  abbr {
    border-bottom: 1px dotted ${({ theme }) => theme.colors.text};
    cursor: help;
  }
  acronym {
    border-bottom: 1px dotted ${({ theme }) => theme.colors.text};
    cursor: help;
  }
  tt,
  code {
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.text};
    padding-bottom: 0.25em;
    padding-top: 0.25em;
    word-break: normal;
  }
  pre code {
    background: none;
  }
  code:before,
  code:after,
  tt:before,
  tt:after {
    content: "\00a0";
    letter-spacing: -0.2em;
  }
  pre code:before,
  pre code:after,
  pre tt:before,
  pre tt:after {
    content: none;
  }
`;

export default GlobalStyle;
