import styled from "styled-components";

export const Header = styled.header`
  position: ${props => (props.retract ? "fixed" : "static")};
  top: 0;
  left: 0;
  z-index: ${props => (props.retract ? 1000 : "unset")};
  width: 100%;
  padding: ${props => (props.retract ? "10px" : "19px")} 0;
  box-shadow: ${props =>
    props.retract
      ? `0 0 20px 0 ${props.theme.colors.headerShadow}`
      : `0 0 0 0 ${props.theme.colors.headerShadow}`};
  background-color: ${({ theme }) => theme.colors.background};
  transition: all 0.3s ease-out;

  @media screen and (max-width: 768px) {
    padding: 15px 0;
    box-shadow: 0 0 20px 0 ${({ theme }) => theme.colors.headerShadow};
  }
`;
