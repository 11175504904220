import styled from "styled-components";

export const Navbar = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const NavbarItem = styled.li`
  position: relative;

  a {
    height: 18px;
    position: relative;
    display: inline-block;
    cursor: pointer;
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
    transition: all 0.45s ease;

    @media screen and (min-width: 768px) {
      font-size: ${({ theme }) => theme.fontSize.md};
    }

    :after {
      position: absolute;
      content: "";
      top: 19px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.secondaryLight};
      opacity: 0.7;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.5s;
    }

    :hover {
      opacity: 0.7;

      :after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }

    &.active {
      cursor: default;
      color: ${({ theme }) => theme.colors.secondaryLight};

      :after {
        opacity: 0;
      }
    }
  }
`;

export const NavbarList = styled.ul`
  display: flex;
  align-items: center;
  margin-left: 0;
  margin-bottom: 0;
  column-gap: 1rem;

  @media screen and (min-width: 768px) {
    margin-left: auto;
    column-gap: 1.5rem;
  }
`;

export const NavbarCollapse = styled.div`
  display: flex;
  flex-basis: auto;
  flex-grow: 1;

  @media screen and (max-width: 767px) {
    position: relative;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
    margin-top: ${({ visible }) => (visible ? "1rem" : "0")};
    height: ${({ visible }) => (visible ? "40px" : 0)};
    flex-basis: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    transition: height 0.5s ease;

    ${NavbarList} ${NavbarItem} a {
      transform: translateY(0);
      transition: all 200ms linear;
    }
  }

  @media screen and (max-width: 380px) {
    flex-direction: column;
    height: ${({ visible }) => (visible ? "80px" : 0)};
    flex-wrap: nowrap;
    justify-content: center;
    row-gap: 1rem;
  }
`;
