import React from 'react';

const IconChevronRight = ({ fit = "24", color = "none" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={fit}
    height={fit}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevron-right"
  >
    <title>Chevron para direita</title>
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);

export default IconChevronRight;
