export const font = {
  main: "Poppins, sans-serif",
  title: "'Frank Ruhl Libre', sans-serif",
  signature: "'Alex Brush', sans-serif",
  basic: "'Open Sans', sans-serif",
};

export const fontSize = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  base: "1.125rem",
  lg: "1.25rem",
  xl: "1.5rem",
  xl2: "1.75rem",
  xl3: "2rem",
  xl4: "2.5rem",
  xl5: "3rem",
  xl6: "4rem",
  xl7: "5rem",
  xl8: "6rem",
};

export const breakpoints = {
  sm: "576px",
  md: "768px",
  lg: "1024px",
  xl: "1200px",
  xxl: "1400px",
};
