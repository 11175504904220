import { font, fontSize } from "../theme";

const light = {
  title: "light",

  colors: {
    secondary: "#0d1e3c",
    secondaryLight: "#627aa3",
    secondaryDark: "#000e27",
    success: "#53d769",
    successDark: "#32aa48",
    successLight: "#77cc86",

    background: "#ffffff",
    backgroundFooter: "linear-gradient(#9a9a9a, #ffffff, #9a9a9a)",
    borderTopFooter: "transparent",
    headerShadow: "rgba(138, 155, 165, 0.15)",
    cardShadow: "rgba(0, 0, 0, 0.2)",
    cardCarouselShadow: "rgba(0, 0, 0, 0.3)",
    backgroundImageCarousel: "rgba(54, 54, 54, 0.7)",
    dropdownShadow: "rgba(9, 9, 12, 0.4)",
    text: "#212121",
    textGray: "#627084",
    textLight: "#ffffff",
    black: "#212121",
    white: "#ffffff",

    iconFooter: "#747474",
    backToTopBg: "#b8b8b8",
    linkActive: "#777777",
    lineLinkHover: "#8167a9",
    burgerIconBg: "#2b3d4f4d",
    tableRowHover: "#d8d8d8",
    gray: "#9a9a9a",
    grayLight: "#d8d8d8",
    grayLightest: "#ebebeb",
  },

  font,
  fontSize,
};

export default light;
