import React from 'react';

const IconChevronDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="square"
    strokeLinejoin="square"
  >
    <title>Chevron para baixo</title>
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

export default IconChevronDown;
