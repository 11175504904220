import React from 'react';

const IconPensionLaw = () => (
  <svg className="area-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <title>Lei de Pensão</title>
    <path fill="currentColor"
          d="M27.013 44.309a3 3 0 0 1-2.136-.885l-9.993-9.993a3.02 3.02 0 0 1 0-4.27 3.093 3.093 0 0 1 4.27 0l9.993 9.993a3.02 3.02 0 0 1-2.134 5.155zm-9.994-14.033a1.02 1.02 0 0 0-.72 1.741l9.993 9.993a1.043 1.043 0 0 0 1.441 0 1.019 1.019 0 0 0 0-1.442l-9.993-9.993a1.017 1.017 0 0 0-.721-.299zm24.271-.246a3.009 3.009 0 0 1-2.135-.883l-9.994-9.994a3.018 3.018 0 0 1 0-4.268 3.091 3.091 0 0 1 4.27 0l9.993 9.993a3.018 3.018 0 0 1-2.134 5.152zm-.721-2.3a1.019 1.019 0 0 0 1.441-1.441L32.016 16.3a1.042 1.042 0 0 0-1.44 0 1.018 1.018 0 0 0 0 1.44z" />
    <path fill="currentColor"
          d="M27.727 40.147a.993.993 0 0 1-.707-.293l-8.566-8.565a1 1 0 0 1 0-1.414l11.421-11.422a1.029 1.029 0 0 1 1.414 0l8.566 8.567a1 1 0 0 1 0 1.414l-11.421 11.42a1 1 0 0 1-.707.293zm-7.152-9.565 7.152 7.151 10.007-10.006-7.152-7.153z" />
    <path fill="currentColor"
          d="M34.151 38.6a3 3 0 0 1-2.135-.885l-1.427-1.427L32 34.872l1.43 1.428a1.043 1.043 0 0 0 1.441 0l1.429-1.428a1.018 1.018 0 0 0 0-1.441L34.872 32l1.414-1.414 1.427 1.428a3.018 3.018 0 0 1 0 4.269l-1.428 1.427a3 3 0 0 1-2.134.89z" />
    <path fill="currentColor"
          d="M44.145 48a1 1 0 0 1-.708-.293l-9.279-9.28 1.414-1.414 8.573 8.573 1.441-1.441-8.573-8.573 1.414-1.414 9.28 9.28a1 1 0 0 1 0 1.414l-2.855 2.855a1 1 0 0 1-.707.293zM27 48H3a3 3 0 0 1-3-3v-2a3 3 0 0 1 3-3h16v2H3a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h25v1a1 1 0 0 1-1 1zM11 11H9a3 3 0 0 1 0-6h2a3 3 0 0 1 3 3h-2a1 1 0 0 0-1-1H9a1 1 0 0 0 0 2h2z" />
    <path fill="currentColor"
          d="M11 15H9a3 3 0 0 1-3-3h2a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2H9V9h2a3 3 0 0 1 0 6zM9 4h2v2H9z" />
    <path fill="currentColor" d="M9 14h2v2H9z" />
    <path fill="currentColor"
          d="M10 20a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10zm0-18a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8zM5 41H3v-2a3 3 0 0 1 3-3h9v2H6a1 1 0 0 0-1 1zm4-18h2v9H9zm-7-2h2v8H2zm14 0h2v5h-2zm32 18h-2V9.414L38.586 2H19V0h20a1 1 0 0 1 .707.293l8 8A1 1 0 0 1 48 9z" />
    <path fill="currentColor" d="M47 10h-8a1 1 0 0 1-1-1V1h2v7h7z" />
</svg>
);

export default IconPensionLaw;
