import React from 'react';

const IconLaborLaw = () => (
  <svg className="area-icon" xmlns="http://www.w3.org/2000/svg" width="356" height="356" viewBox="0 0 140 140">
    <title>Direito Trabalhista</title>
    <path fill="currentColor"
        d="M77.301 64.671h-2.602v-4h2.602v4zm-6.602 0h-4v-4h4v4zm-8 0h-4v-4h4v4zm-8 0h-4v-4h4v4zm-29.401 8.608h-6.726C7.382 73.279.151 64.758.151 51.57V33.933h4V51.57c0 8.562 3.789 17.709 14.421 17.709h6.726v4zm84.13 0h-6.791v-4h6.791c10.645 0 14.422-9.61 14.422-17.84V33.801h4V51.44c0 13.062-7.404 21.839-18.422 21.839zm-72.31-44.824h4v30.398h-4zm-10.551 0h4v30.398h-4zm10.551 55.053h4v30.398h-4zm-10.551 0h4v30.398h-4z" />
    <path fill="currentColor"
        d="M40.162 86.107H27.524c-2.276 0-4.128-1.864-4.128-4.156V61.658c0-2.29 1.852-4.154 4.128-4.154h12.638c2.275 0 4.126 1.864 4.126 4.154v20.293c0 2.292-1.851 4.156-4.126 4.156zM27.524 61.504c-.07 0-.128.069-.128.154v20.293c0 .088.056.156.128.156h12.638c.061 0 .126-.06.126-.156V61.658c0-.092-.065-.154-.126-.154H27.524z" />
    <path fill="currentColor"
        d="M31.843 75.555h4v7.213h-4zM97.434 28.455h4v30.398h-4zm-10.55 0h4v30.398h-4zm10.55 55.053h4v30.398h-4zm-10.55 0h4v30.398h-4z" />
    <path fill="currentColor"
        d="M100.479 86.107H87.84c-2.276 0-4.128-1.864-4.128-4.156V61.658c0-2.29 1.852-4.154 4.128-4.154h12.639c2.274 0 4.125 1.864 4.125 4.154v20.293c0 2.292-1.851 4.156-4.125 4.156zM87.84 61.504c-.07 0-.128.069-.128.154v20.293c0 .086.058.156.128.156h12.639c.061 0 .125-.063.125-.156V61.658c0-.092-.064-.154-.125-.154H87.84z" />
    <path fill="currentColor" d="M92.16 75.555h4v7.213h-4zM42.213 69.279h43.572v4H42.213z" />
    <path fill="currentColor"
        d="M128 116.846H0V26.108h128v90.738zm-124-4h120V30.108H4v82.738zM82.62 21.794h-4v-6.64H49.379v6.64h-4v-10.64H82.62z" />
</svg>
);

export default IconLaborLaw;
