import React from 'react';

const IconChevronLeft = ({ fit = "24", color = "none" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={fit}
    height={fit}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-chevron-left"
  >
    <title>Chevron para esquerda</title>
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
);

export default IconChevronLeft;
