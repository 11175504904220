import styled from "styled-components";

export const MobileMenu = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const Line = styled.div`
  position: absolute;
  left: 25%;
  width: 80%;
  height: 3px;
  background-color: ${({ theme }) => theme.colors.burgerIconBg};
  border-radius: 10px;
  overflow: hidden;
  transition: all 200ms linear;

  :nth-child(1) {
    top: 12%;
    :after {
      transition-delay: 0.1s;
    }
  }
  :nth-child(2) {
    top: 42%;
    :after {
      transition-delay: 0.2s;
    }
  }
  :nth-child(3) {
    top: 72%;
    :after {
      transition-delay: 0.3s;
    }
  }

  :after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.secondary};
    transform: translateX(-100%);
    transition: 0.25s;
  }
`;

export const MobileMenuIcon = styled.label`
  position: absolute;
  top: 9px;
  right: 3px;
  display: inline-block;
  width: 30px;
  height: 30px;
  border: none;
  outline: none;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    display: none;
  }

  :hover {
    ${Line}:after {
      transform: translateX(0);
    }
  }
`;

export const MobileMenuInput = styled.input`
  opacity: 0;
  visibility: hidden;

  :checked {
    ~ ${MobileMenuIcon} {
      ${Line} {
        background: transparent;

        :after {
          transform: translateX(0);
        }
        :nth-child(1) {
          transform: translateY(calc(45px / 5)) rotate(45deg);
        }
        :nth-child(2) {
          transform: scaleX(0);
        }
        :nth-child(3) {
          transform: translateY(calc(45px / -5)) rotate(-45deg);
        }
      }
    }
  }
`;
