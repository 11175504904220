import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Link as ScrollLink } from "react-scroll";
import { ThemeContext } from "styled-components";
import Icon from "../Icons/icon";
import { redirectLinks } from "../../utils/redirect-links";
import BackToTopButton from "../BackToTopBtn";
import * as S from "./styles";

const Footer = () => {
  const { title } = useContext(ThemeContext);
  const isDesktop = useMediaQuery({ minWidth: 576 });

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          description
        }
      }
    }
  `);

  return (
    <S.Footer>
      <BackToTopButton />

      <S.FooterContent>
        <S.About>
          <div className="description">
            {title === "dark" ? (
              isDesktop ? (
                <StaticImage
                  src="../../images/logo-white.png"
                  width={200}
                  layout="fixed"
                  loading="eager"
                  alt="logo do site escrita GM Advogados"
                />
              ) : (
                <StaticImage
                  src="../../images/sublogo-white.png"
                  width={100}
                  layout="fixed"
                  loading="eager"
                  alt="logo do site em formato redondo escrita GM Advogados"
                />
              )
            ) : isDesktop ? (
              <StaticImage
                src="../../images/logo-black.png"
                width={200}
                layout="fixed"
                loading="eager"
                alt="logo do site escrita GM Advogados"
              />
            ) : (
              <StaticImage
                src="../../images/sublogo-black.png"
                width={100}
                layout="fixed"
                loading="eager"
                alt="logo do site em formato redondo escrita GM Advogados"
              />
            )}

            <p>{data.site.siteMetadata?.description}</p>
          </div>

          <div className="social-media">
            <a
              href="https://www.facebook.com/JeanMedeirosAdv"
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="FacebookSquare" aria-label="Ícone do facebook quadrado" />
            </a>
            <a
              href="https://www.instagram.com/jeaneverson/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="LinkedinSquare" aria-label="Ícone do linkedin quadrado" />
            </a>
            <a
              href="https://www.instagram.com/jeaneverson/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="InstagramSquare" aria-label="Ícone do instagram quadrado" />
            </a>
            <a
              href="https://wa.me/5561983002735"
              target="_blank"
              rel="noreferrer"
            >
              <Icon name="WhatsappSquare" aria-label="Ícone do whatsapp quadrado" />
            </a>
          </div>
        </S.About>

        <S.Areas>
          <S.Title>Áreas de Atuação</S.Title>

          <ul>
            <li>
              <Link to="/aposentadoria-e-auxilios">
                Aposentadoria e Auxílios
              </Link>
            </li>
            <li>
              <Link to="/direito-trabalhista">Direito Trabalhista</Link>
            </li>
            <li>
              <Link to="/direito-do-consumidor">Direito do Consumidor</Link>
            </li>
          </ul>
        </S.Areas>

        <S.Info>
          <S.Contact>
            <S.Title>Contato</S.Title>

            <ul>
              <li>
                <Icon name="Mail" aria-label="Ícone do email" />
                <a href="mailto:guimaraesmedeirosadv@gmail.com?subject=Assunto do email&body=Conteúdo do email">
                  <span>guimaraesmedeirosadv@gmail.com</span>
                </a>
              </li>
              <li>
                <Icon name="Phone" aria-label="Ícone do telefone" />
                <a href="tel:(61) 983002735">
                  <span>(61) 9 8300-2735</span>
                </a>
              </li>
            </ul>
          </S.Contact>

          <S.WorkingHours>
            <S.Title>Horário de Atendimento</S.Title>

            <ul>
              <li>
                <span>Segunda a Sexta</span>
                <span>09:00 - 18:00</span>
              </li>
            </ul>
          </S.WorkingHours>
        </S.Info>
      </S.FooterContent>

      <S.FooterBottom>
        <p>© {new Date().getFullYear()} | Todos os direitos reservados</p>

        <S.RedirectList>
          {redirectLinks.map((link, index) => (
            <S.RedirectItem key={index}>
              <ScrollLink
                activeClass="active"
                spy={true}
                to={link.anchor}
                offset={isDesktop ? -250 : -280}
              >
                {link.text}
              </ScrollLink>

              {index !== redirectLinks.length - 1 && <i>·</i>}
            </S.RedirectItem>
          ))}
        </S.RedirectList>
      </S.FooterBottom>
    </S.Footer>
  );
};

export default Footer;
