import React from 'react';

const IconConsumerCivilLaw = () => (
  <svg className="area-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <title>Direito Civil do Consumidor</title>
    <path
      fill="currentColor"
      d="M14 32a7.008 7.008 0 0 1-7-7v-6a7 7 0 0 1 14 0v6a7.008 7.008 0 0 1-7 7zm0-18a5.006 5.006 0 0 0-5 5v6a5 5 0 0 0 10 0v-6a5.006 5.006 0 0 0-5-5z"
    />
    <path
      fill="currentColor"
      d="M20 22c-4.8 0-7.3-.848-8.6-1.773A4.106 4.106 0 0 1 8 22l-.007-2A1.884 1.884 0 0 0 10 18a1 1 0 0 1 2 0c.018 0 .708 2 8 2zm1 4h-1v-5h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1z"
    />
    <path
      fill="currentColor"
      d="M8 26H7a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1zm6 9a3 3 0 0 1-3-3v-1h2v1a1 1 0 0 0 2 0v-1h2v1a3 3 0 0 1-3 3z"
    />
    <path
      fill="currentColor"
      d="M27 48H1a1 1 0 0 1-1-1v-6a9.01 9.01 0 0 1 9-9v2a7.008 7.008 0 0 0-7 7v5h24v-5a7.008 7.008 0 0 0-7-7v-2a9.01 9.01 0 0 1 9 9v6a1 1 0 0 1-1 1z"
    />
    <path
      fill="currentColor"
      d="M14 44a1 1 0 0 1-.707-.293l-9-9 1.414-1.414L14 41.586l8.293-8.293 1.414 1.414-9 9A1 1 0 0 1 14 44z"
    />
    <path
      fill="currentColor"
      d="M13 43h2v4h-2zm-8 0h2v4H5zm16 0h2v4h-2zm20-17a7.008 7.008 0 0 1-7-7 1 1 0 0 1 1-1h12a1 1 0 0 1 1 1 7.008 7.008 0 0 1-7 7zm-4.9-6a5 5 0 0 0 9.8 0z"
    />
    <path
      fill="currentColor"
      d="M46.143 19.515 41 10.944l-5.143 8.571-1.714-1.03 6-10a1.039 1.039 0 0 1 1.714 0l6 10z"
    />
    <path
      fill="currentColor"
      d="M19 8h22v2H19zm11-2a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
    />
    <path fill="currentColor" d="M29 5h2v24h-2z" />
    <path
      fill="currentColor"
      d="M38 34H27v-2h10v-1a1 1 0 0 0-1-1H24a1 1 0 0 0-1 1h-2a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v2a1 1 0 0 1-1 1z"
    />
  </svg>
);

export default IconConsumerCivilLaw;
