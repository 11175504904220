import React from "react";
import PropTypes from "prop-types";
import {
  IconArrowRightSmall,
  IconArrowRight,
  IconBusinessLaw,
  IconCheck,
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconConsumerCivilLaw,
  IconFacebookSquare,
  IconFacebook,
  IconInstagramSquare,
  IconInstagram,
  IconJuricialLaw,
  IconLaborLaw,
  IconLinkedinSquare,
  IconLinkedin,
  IconLogoBlack,
  IconLogoWhite,
  IconMail,
  IconPensionLaw,
  IconPhone,
  IconQuotes,
  IconRealEstateLaw,
  IconWhatsappSquare,
  IconWhatsapp
} from "./";

const Icon = ({ name }) => {
  switch (name) {
    case "ArrowRightSmall":
      return <IconArrowRightSmall />;
    case "ArrowRight":
      return <IconArrowRight />;
    case "BusinessLaw":
      return <IconBusinessLaw />;
    case "Check":
      return <IconCheck />;
    case "ChevronDown":
      return <IconChevronDown />;
    case "ChevronLeft":
      return <IconChevronLeft />;
    case "ChevronRight":
      return <IconChevronRight />;
    case "ConsumerCivilLaw":
      return <IconConsumerCivilLaw />;
    case "FacebookSquare":
      return <IconFacebookSquare />;
    case "Facebook":
      return <IconFacebook />;
    case "InstagramSquare":
      return <IconInstagramSquare />;
    case "Instagram":
      return <IconInstagram />;
    case "JuricialLaw":
      return <IconJuricialLaw />;
    case "LaborLaw":
      return <IconLaborLaw />;
    case "LinkedinSquare":
      return <IconLinkedinSquare />;
    case "Linkedin":
      return <IconLinkedin />;
    case "LogoBlack":
      return <IconLogoBlack />;
    case "LogoWhite":
      return <IconLogoWhite />;
    case "Mail":
      return <IconMail />;
    case "PensionLaw":
      return <IconPensionLaw />;
    case "Phone":
      return <IconPhone />;
    case "Quotes":
      return <IconQuotes />;
    case "RealEstateLaw":
      return <IconRealEstateLaw />;
    case "WhatsappSquare":
      return <IconWhatsappSquare />;
    case "Whatsapp":
      return <IconWhatsapp />;
    default:
      return null;
  }
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
