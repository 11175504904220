import styled from "styled-components";
import { ContainerBaseStyle } from "../../styles/general-styles";

export const HeaderTopContainer = styled.div`
  display: none;
  width: 100%;
  padding: 10px 0;
  background-color: ${({ theme }) => theme.colors.secondary};
  transition: background-color 0.45s ease-out;

  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const HeaderTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 15px;

  ${ContainerBaseStyle};

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 1.5rem;
    margin-bottom: 0;

    li {
      display: inline-flex;
      align-items: center;
      column-gap: 0.5rem;

      a {
        display: inline-flex;
        align-items: center;
        column-gap: 0.5rem;

        svg {
          width: 16px;
          height: 16px;
        }

        span {
          font-size: ${({ theme }) => theme.fontSize.sm};
          font-weight: 500;
          color: ${({ theme }) => theme.colors.white};
          transition: all 200ms linear;
        }
      }
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.75rem;

    @media screen and (min-width: 768px) {
      column-gap: 1.25rem;
    }

    a,
    svg {
      width: 16px;
      height: 16px;
    }

    a {
      width: 16px;
      height: 16px;
      transition: all 0.25s ease;

      :hover {
        opacity: 0.7;
      }
    }
  }
`;
